<template>
  <div>
    <b-row>
      <b-col>
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card v-else>
          <!-- Competition edit: Input Fields -->
          <validation-observer ref="competitionValidation">
            <b-form @submit.prevent="validationForm">
              <b-row>
                <!-- Field: Name -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Nom"
                    label-for="name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required|max:100"
                    >
                      <b-form-input
                        id="name"
                        v-model="competition.name"
                        placeholder="ccup"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="server_errors && server_errors['name']"
                        class="text-danger"
                      >{{ server_errors["name"][0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field: status -->
                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                >
                  <b-form-group
                    label="Status"
                    label-for="Status"
                  >
                    <v-select
                      id="Status"
                      v-model="competition.status"
                      :dir="'ltr'"
                      :options="statusOptions"
                      :clearable="false"
                      :reduce="val => val.value"
                    />
                    <small
                      v-if="server_errors && server_errors['status']"
                      class="text-danger"
                    >{{ server_errors['status'][0] }}</small>
                  </b-form-group>
                </b-col>

                <!-- Field: sport -->
                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                >
                  <b-form-group
                    label="Sport"
                    label-for="sport"
                  >
                    <v-select
                      id="sport"
                      v-model="competition.sport"
                      :dir="'ltr'"
                      :options="sportsOptions"
                      :clearable="false"
                      :reduce="val => val.value"
                    />
                    <small
                      v-if="server_errors && server_errors['status']"
                      class="text-danger"
                    >{{ server_errors['status'][0] }}</small>
                  </b-form-group>
                </b-col>

                <!-- Field: start_at -->
                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                >
                  <b-form-group
                    label="Date de début"
                    label-for="start_at"
                  >
                    <flat-pickr
                      v-model="competition.start_at"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', time_24hr : true}"
                    />
                    <small
                      v-if="server_errors && server_errors['start_at']"
                      class="text-danger"
                    >{{ server_errors['start_at'][0] }}</small>
                  </b-form-group>
                </b-col>

                <!-- Field: end_at -->
                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                >
                  <b-form-group
                    label="Date de fin"
                    label-for="start_at"
                  >
                    <flat-pickr
                      v-model="competition.end_at"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', time_24hr : true}"
                    />
                    <small
                      v-if="server_errors && server_errors['end_at']"
                      class="text-danger"
                    >{{ server_errors['end_at'][0] }}</small>
                  </b-form-group>
                </b-col>

                <!-- Field: Name -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Api url"
                    label-for="api_url"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="url api"
                      rules="max:200"
                    >
                      <b-form-input
                        id="api_url"
                        v-model="competition.api_url"
                        placeholder="ccup"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="server_errors && server_errors['api_url']"
                        class="text-danger"
                      >{{ server_errors["api_url"][0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field: is_visible -->
<!--                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                >
                  <b-form-group
                    label="Gérable par le client :"
                    label-for="is_editable_by_client"
                  >
                    <b-form-checkbox
                      id="is_editable_by_client"
                      v-model="competition.is_editable_by_client"
                      :value="true"
                      :unchecked-value="false"
                    >
                      <span class="vs-label">{{ competition.is_editable_by_client ? 'oui' : 'non' }}</span>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>-->

                <!-- Field: is_visible -->
                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                >
                  <b-form-group
                    label="Competition commandable :"
                    label-for="is_orderable"
                  >
                    <b-form-checkbox
                      id="is_orderable"
                      v-model="competition.is_orderable"
                      :value="true"
                      :unchecked-value="false"
                    >
                      <span class="vs-label">{{ competition.is_orderable ? 'oui' : 'non' }}</span>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <!-- Field: image -->
                <b-col
                  cols="12"
                  lg="6"
                  md="6"
                >
                  <template v-if="isImgLink">
                    <b-form-group label="Image">
                      <b-img
                        height="auto"
                        width="100"
                        :src="competition.image"
                      />
                      <b-button
                        variant="warning"
                        class="ml-1 btn-icon"
                        @click="removeImage"
                      >
                        <feather-icon icon="RefreshCwIcon" />
                      </b-button>
                    </b-form-group>
                  </template>
                  <template v-else>
                    <b-form-group
                      label="Image"
                    >
                      <b-form-file
                        v-model="competition.image"
                        accept="image/jpeg, image/png, image/gif"
                        placeholder="Choisissez un fichier ou déposez-le ici..."
                        drop-placeholder="Déposer le fichier ici..."
                        :state="Boolean(competition.image)"
                      />
                      <small
                        v-if="server_errors && server_errors['image']"
                        class="text-danger"
                      >{{ server_errors['image'][0] }}</small>
                    </b-form-group>
                  </template>
                </b-col>
              </b-row>
              <b-button
                variant="primary"
                type="submit"
                :disabled="formLoading || !isCompetitionModified"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              >
                <b-spinner
                  v-show="formLoading"
                  small
                />
                Enregistrer
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox, BFormFile,
  BFormGroup,
  BFormInput, BImg,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import {
  max,
  max_value,
  min,
  min_value,
  required,
} from '@core/utils/validations/validations'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'CompetitionsEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormCheckbox,
    vSelect,
    BSpinner,
    flatPickr,
    BImg,
    BFormFile,
  },
  data() {
    return {
      loading: true,
      formLoading: false,
      competition: {},
      server_errors: null,
      competitionReference: null,
      required,
      max,
      min,
      max_value,
      min_value,
      sportsOptions: [
        { label: 'Foot', value: 'Football' },
        { label: 'Basket', value: 'Basketball' },
        { label: 'Rugby', value: 'Rugby' },
        { label: 'Hockey', value: 'Hockey' },
        { label: 'Tennis', value: 'Tennis' },
        { label: 'autre', value: 'other' },
      ],
      statusOptions: [
        { label: 'À venir', value: 'PENDING' },
        { label: 'En cours', value: 'RUNNING' },
        { label: 'Terminé', value: 'FINISHED' },
      ],
    }
  },
  computed: {
    isCompetitionModified() {
      return JSON.stringify(this.competition) !== this.competitionReference
    },
    isImgLink() {
      const regex = /(https?:\/\/.*\.(?:png|jpg))/
      if (this.competition.image && typeof this.competition.image === 'string' && this.competition.image.match(regex)) return true
      return false
    },
  },
  mounted() {
    this.fetchCompetition()
  },
  methods: {
    async fetchCompetition() {
      try {
        this.loading = true
        const competition = await axiosIns.get(
          `competitions/${this.$route.params.id}`,
        )
        this.competitionReference = JSON.stringify(competition.data.data)
        this.competition = competition.data.data
        this.$store.commit('app/UPDATE_PAGE_META', {
          pageTitle: this.competition.name,
          breadcrumb: [
            {
              text: 'Competitions',
              active: false,
              to: {
                name: 'competitions',
              },
            },
            {
              text: this.competition.name,
              active: false,
              to: {
                name: 'competitions-view',
                params: { id: this.competition.uuid },
              },
            },
            {
              text: 'éditer',
              active: true,
            },
          ],
        })
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
    async editCompetition() {
      try {
        this.formLoading = true
        this.server_errors = null
        const data = new FormData()
        data.append('name', this.competition.name)
        data.append('sport', this.competition.sport)
        data.append('status', this.competition.status)
        if (this.competition.api_url) {
          data.append('api_url', this.competition.api_url)
        }
        data.append('start_at', this.competition.start_at)
        data.append('end_at', this.competition.end_at)
        data.append('image', this.competition.image)
        data.append('is_editable_by_client', this.competition.is_editable_by_client)
        data.append('is_orderable', this.competition.is_orderable)
        const updatedCompetition = await axiosIns.post(`competitions/${this.$route.params.id}`, data)
        this.competition = updatedCompetition.data.data
        this.competitionReference = JSON.stringify(updatedCompetition.data.data)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La compétition à bien été mise a jour',
            },
          },
          { timeout: 5000 },
        )
        this.formLoading = false
      } catch (err) {
        this.formLoading = false
        this.server_errors = err.response.data.errors
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    removeImage() {
      this.competition.image = null
    },
    validationForm() {
      this.$refs.competitionValidation.validate().then(success => {
        if (success) {
          this.editCompetition()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
